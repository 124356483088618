<template>
  <transition name="fade-in">
    <component
      :is="`Svgo${toUpperCamelCase(icon)}`"
      :class="{ 'is-raw': isRaw }"
      class="app-icon"
      :style="`--max-width: ${maxWidth}; --fill: ${fill}; --stroke: ${stroke};`"
    />
  </transition>
</template>

<script setup>
defineProps({
    block: {
        type: Object,
        required: false,
        default: () => {},
    },

    icon: {
        type: [String, Boolean],
        required: false,
        default: false,
    },

    fill: {
        type: String,
        required: false,
        default: 'transparent',
    },

    stroke: {
        type: String,
        required: false,
        default: 'black',
    },

    maxWidth: {
        type: String,
        default: '10px',
    },

    isRaw: {
        type: Boolean,
        default: false,
    },
});

const toUpperCamelCase = (str) => str
    .replace(/[-_](.)/g, (_, c) => c.toUpperCase())
    .replace(/^(.)/, (_, c) => c.toUpperCase());

</script>

<style lang="scss">
.app-icon {

    &:not(.is-raw) {
        width: 500px;
        max-width: var(--max-width);
        path {
            fill: var(--fill);
            pointer-events: none;
            stroke: var(--stroke);
        }
    }

    .atom-flag & {
        path {
            fill: $C_WHITE;
        }
    }

    .has-information & {
        path {
            fill: $C_GREEN_DARK;
        }
    }

    .atom-flag__info-button & {
        path {
            fill: $C_PRIMARY;
        }
    }

    .filter-tags__scroll-control, .filter-secondary__scroll-control, .filter-blogs__scroll-control & {
        width: 34px;

        path {
            fill: $C_WHITE;
        }
    }
}
</style>
